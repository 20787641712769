import { User } from '../types/api/UsersTypes';
import axios from 'axios';
import jsPDF from 'jspdf';

const downloadLicenseCertificatePdf = async (user: User, callback: () => void) => {
  try {
    const response = await axios.post('/api/generate-license-certificate-html', { user });
    const htmlString = response.data.html;
    const pdf = new jsPDF();

    pdf.html(htmlString, {
      callback: doc => {
        doc.save('download.pdf');
        callback();
      },
      x: 10,
      y: 10,
      width: 180,
      windowWidth: 800,
    });

  } catch (error) {
    throw { error: 'An error has occurred' };
  }
};

const licenseCertificateHelpers = {
  downloadLicenseCertificatePdf,
};

export default licenseCertificateHelpers;
