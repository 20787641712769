import React from 'react';
import Link from 'next/link';
import { SocialIcon, Logo } from '../modules/Icons';
import { connect } from 'react-redux';
import { ApplicationState } from '../types/state/storeTypes';
import { FooterScripts } from '../components/shared/Scripts';
import ActiveCampaignWidget from '../components/active-campaign-widget/activeCampaignWidget';

const Footer: React.FC<any> = (props) => {
  const labelClass = 'inter text-white uppercase text-[14px] font-medium pb-2';
  const bodyClass = 'py-2 !font-light text-[14px]';
  return (
    <>
      <footer className=" bg-a-darker-gray text-a-light-gray text-[14px] z-20 ">
        <div className="flex grid grid-flow-row gap-8 px-8 py-6 lg:grid-flow-col-dense">
          {/* COL 1 */}
          <div className="lg:col-span-[1.5]">
            <Logo />
            <p className="pt-12 pb-4 max-w-[326px]">
              ActionVFX is the only resource you need for production-quality stock footage. VFX is
              hard, but with our library at your disposal, achieving great results is possible and
              fast!
            </p>
            <Link className="block text-a-blue" href="/about-us">
              Read our story
            </Link>
          </div>

          {/* COL 2 */}
          <div>
            <h5 className={labelClass}>Product</h5>
            <ul>
              <li className={bodyClass}>
                <Link href="/products">All Collections</Link>
              </li>
              <li className={bodyClass}>
                <Link href="/licenses">License Agreement</Link>
              </li>
              <li className={bodyClass}>
                <Link href="https://actionvfx.featurebase.app/?b=65bfb4720ed972a2431dad35">Request a Product</Link>
              </li>
              <li className={bodyClass}>
                <Link href="https://actionvfx.featurebase.app/roadmap">Product Roadmap</Link>
              </li>
              <li className={bodyClass}>
                <Link href="https://sell.actionvfx.com/">Sell on ActionVFX</Link>
              </li>
            </ul>
          </div>

          {/* COL 3 */}
          <div>
            <h5 className={labelClass}>Content</h5>
            <ul>
              <li className={bodyClass}>
                <Link href="/blog?category=tutorials">Tutorials</Link>
              </li>
              <li className={bodyClass}>
                <Link href="/blog">Blog</Link>
              </li>
            </ul>
          </div>

          {/* COL 4 */}
          <div>
            <h5 className={labelClass}>Company</h5>
            <ul>
              <li className={bodyClass}>
                <Link href="/about-us">About Us</Link>
              </li>
              <li className={bodyClass}>
                <Link href="/testimonials">Testimonials</Link>
              </li>
              <li className={bodyClass}>
                <Link href="/brand">Brand Resources</Link>
              </li>
              <li className={bodyClass}>
                <Link href="https://actionvfx.bamboohr.com/careers">Jobs</Link>
              </li>
            </ul>
          </div>

          {/* COL 5 */}
          <div>
            <h5 className={labelClass}>Support</h5>
            <ul>
              <li className={bodyClass}>
                <Link href="mailto:support@actionvfx.com">support@actionvfx.com</Link>
              </li>
              <li className={bodyClass}>
                <Link href="mailto:sales@actionvfx.com">sales@actionvfx.com</Link>
              </li>
              <li className={bodyClass}>
                <Link href="/faq">FAQ</Link>
              </li>
            </ul>
          </div>

          {/* COL 6 */}
          <div className="order-last">
            <div className="w-11/12 pb-4 overflow-hidden">
              <ActiveCampaignWidget />
            </div>
            <div className="flex flex-wrap gap-3 py-8">
              <SocialIcon site="facebook" link="https://www.facebook.com/actionvfx"></SocialIcon>
              <SocialIcon site="twitter" link="https://twitter.com/actionVFX"></SocialIcon>
              <SocialIcon
                site="youtube"
                link="https://www.youtube.com/channel/UCqygDczqPpXGYqHXtcsmyFg"></SocialIcon>
              <SocialIcon
                site="instagram"
                link="https://www.instagram.com/actionvfxofficial"></SocialIcon>
              <SocialIcon
                site="linkedin"
                link="https://www.linkedin.com/company/10800607"></SocialIcon>
              <SocialIcon
                site="tiktok"
                link="https://www.tiktok.com/@actionvfxofficial"></SocialIcon>
            </div>
          </div>
        </div>

        <div className="px-8 py-12 md:flex text-[#4D5457]">
          <div className="py-4 md:w-1/2 lg:w-9/12 md:py-0">
            &copy; {new Date().getFullYear()} RodyPolis LLC. All rights reserved.
          </div>
          <div className="flex flex-wrap justify-center gap-8 md:flex-nowrap">
            <Link href="/terms-of-service">Terms</Link>
            <Link href="/privacy-policy">Privacy</Link>
            <Link href="/license">License Agreement</Link>
          </div>
        </div>
      </footer>
      <FooterScripts user={props.user} subscription={props.subscriptions} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  authenticated: state.auth.authenticated,
  subscription: state.auth.subscription,
  user: state.auth.user
});

export default connect(mapStateToProps)(Footer);
